import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import ViddgetLogo from '../images/viddget_logo_white.png';

import Header from "./header"
import "./layout.scss"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div>
          <main>{children}</main>
          <footer className="footer">
            <div className="container space-between">
            <div className="col">
              <img src={ViddgetLogo} alt="Viddget logo" />
            </div>
            <div className="col">
              <p>Viddget AB <br/>
              559155-4042</p>
            </div>
            <div className="col">
                <p>Kungsgatan 8 <br/>
              111 43 Stockholm, Sweden</p>
            </div>
            <div className="col">
              <p>
              <a href="mailto:info@viddget.com">info@viddget.com</a> <br/>
              <a href="tel:+46840020000">+46 8 400 200 00</a>
              </p>
            </div>
            </div>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
