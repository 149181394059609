import React from 'react'
import { Link } from 'gatsby'
import Logo from '../images/viddget-logo.png'

export default class Menu extends React.Component {
    constructor() {
    super();
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.state = {
        isOpen: false,
        isTop: true
    };
    }

    componentDidMount() {
        document.addEventListener('scroll', () => {
          const isTop = window.scrollY < 100;
          if (isTop !== this.state.isTop) {
              this.setState({ isTop })
          }
        });
      }

    onToggleMenu() {
    this.setState({
        isOpen: !this.state.isOpen
    });
    }
    render() {
        return (

        <nav className={`menu ${this.state.isTop ? '' : 'is-scroll'}`}>
            <div className="menu-wrapper">
                <Link to="/">
                    <img src={Logo} alt="Viddget" className="logo" />
                </Link>
                <div className={`hamburger ${this.state.isOpen ? 'is-open' : ''}`} onClick={this.onToggleMenu}>
                <div className="hamburger-box">
                    <div className="hamburger-inner"></div>
                </div>
                {this.state.isOpen && 
                    <ul className="links">
                        <li><Link onClick={this.onToggleMenu} activeClassName="active" to="#what-is-viddget">What is Viddget</Link></li>
                        <li><Link onClick={this.onToggleMenu} activeClassName="active" to="#book-a-demo">Book a demo</Link></li>
                        <li><Link onClick={this.onToggleMenu} activeClassName="active" to="#products">Products</Link></li>
                        <li><Link onClick={this.onToggleMenu} activeClassName="active" to="#how-to-use">How to use Viddget</Link></li>
                    </ul>
                }
                </div>
            </div>
        </nav>
        )
    }
}